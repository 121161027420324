import React, { useState, useContext, useEffect } from "react"
import VideoCentral from "./VideoCentral"
import SliderLeft from "./SliderLeft"
import SliderRight from "./SliderRight"
import LoadingDotsIcon from "./LoadingDotsIcon"
import DispatchContext from "../DispatchContext"
import Page from "./Page"
import Axios from "axios"

function MainEl() {
  const videoNames = ["Aleph News", "Aleph Business", "Smart TV"]
  const [isLoading, setIsLoading] = useState(true)
  const appDispatch = useContext(DispatchContext)
  const loadingSources = []
  const infoSources = [process.env.VideoAlephNews.src || "https://alephnews.ro/design-config.json", process.env.VideoAlephBusiness.src || "https://alephbusiness.ro/design-config.json", process.env.VideoSmartTV.src || "https://smartv.media/design-config.json"]

  async function fillVideoSources() {
    for (const [index, videoSource] of infoSources.entries()) {
      const response = await Axios.get(videoSource)
      const tmpStream = {
        name: videoNames[index],
        portrait: response.data[0]["portraitUrl"],
        landscape: response.data[0]["landScapeUrl"]
      }
      loadingSources.push(tmpStream)
    }
    appDispatch({ type: "setVideoSources", value: loadingSources })
    setIsLoading(false)
  }

  useEffect(() => {
    fillVideoSources()
  }, [])

  if (isLoading)
    return (
      <Page title="...">
        <LoadingDotsIcon />
      </Page>
    )

  return (
    <>
      <main>
        <VideoCentral />
        <SliderLeft />
        <SliderRight />
      </main>
    </>
  )
}

export default MainEl
