import React from "react"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTh } from "@fortawesome/free-solid-svg-icons"

function Categs() {
  return (
    <>
      <nav id="categs" className="categories text-container">
        <p className="head">
          <FontAwesomeIcon icon={faTh} />
          Categorii
        </p>
        <ul>
          <li>
            <h2>
              <Link to="/categories/5" title="Business">
                Business
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/9" title="Educație">
                Educație
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/12" title="Entertainment">
                Entertainment
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/6" title="Government">
                Government
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/7" title="Justiție">
                Justiție
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/217" title="Meteo">
                Meteo
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/216" title="Sănătate">
                Sănătate
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/13" title="Sport">
                Sport
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/218" title="Tehnologie">
                Tehnologie
              </Link>
            </h2>
          </li>
          <li>
            <h2>
              <Link to="/categories/1" title="Cultură">
                Cultură
              </Link>
            </h2>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Categs
