import React, { useContext, useEffect } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"
import ForYou from "./ForYou"

function ForYouPage(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  useEffect(() => {
    appDispatch({ type: "toggleSidebar", value: true })
  }, [])

  useEffect(() => {
    document.title = `${props.title} | Aleph`
    window.scrollTo(0, 0)
  }, [props.title])

  return (
    <>
      <aside id="sidebar" className={`sidebar ${appState.sidebarClass}`}>
        <ForYou />
      </aside>{" "}
    </>
  )
}

export default ForYouPage
