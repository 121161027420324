import React, { useContext, useEffect } from "react"
import DispatchContext from "../DispatchContext"

function Homepage(props) {
  const appDispatch = useContext(DispatchContext)

  useEffect(() => {
    appDispatch({ type: "toggleSidebar", value: false })
  }, [])

  useEffect(() => {
    document.title = `${props.title} | Aleph`
    window.scrollTo(0, 0)
  }, [props.title])

  return <></>
}

export default Homepage
