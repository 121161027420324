import React, { useEffect, useState, useContext } from "react"
import Axios from "axios"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList } from "@fortawesome/free-solid-svg-icons"

import Page from "./Page"
import LoadingDotsIcon from "./LoadingDotsIcon"
import StateContext from "../StateContext"

function ForYou() {
  const [isLoading, setIsLoading] = useState(true)
  const [articlesForYou, setForYou] = useState()
  const appState = useContext(StateContext)
  const articlesForYouSource = [process.env.ForYou || "https://alephnews.ro/wp-json/app/v1/titlurile-zilei"]

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source()
    async function fetchForYou() {
      try {
        const response = await Axios.get(articlesForYouSource, { cancelToken: ourRequest.token })
        setForYou(response.data)
        setIsLoading(false)
      } catch (e) {
        console.log("Nu am putut descărca json-ul cu articole recomandate de la {articlesForYouSource} sau requestul a fost întrerupt la cerere.")
      }
    }
    fetchForYou()
    return () => {
      ourRequest.cancel()
    }
  }, [appState.articlesForYou])

  if (isLoading)
    return (
      <Page title="...">
        <LoadingDotsIcon />
      </Page>
    )

  return (
    <>
      <div id="for-you" className="text-container">
        <p className="head">
          <FontAwesomeIcon icon={faList} />
          For you
        </p>
        <ul className="news">
          <ul className="news">
            {Object.keys(articlesForYou).map((key, index) => {
              return (
                <li key={articlesForYou[key].id}>
                  <Link to={`/article/${articlesForYou[key].id}`}>{articlesForYou[key].title}</Link>
                </li>
              )
            })}
          </ul>
        </ul>
      </div>
    </>
  )
}

export default ForYou
