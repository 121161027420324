import React, { useEffect, useState, useContext } from "react"
import { useParams, Link } from "react-router-dom"
import Axios from "axios"
import LoadingDotsIcon from "./LoadingDotsIcon"
import StateContext from "../StateContext"
import Page from "./Page"

function Article() {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [article, setArticle] = useState()
  const appState = useContext(StateContext)
  const articleSource = [process.env.articleSource || "https://alephnews.ro/wp-json/wp/v2/posts/"]

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source()
    async function fetchArticle() {
      try {
        const response = await Axios.get(articleSource + id, { cancelToken: ourRequest.token })
        setArticle(response.data)
        setIsLoading(false)
      } catch (e) {
        console.log("Nu am putut descărca articolul de la {articleSource} sau requestul a fost întrerupt la cerere.")
      }
    }
    fetchArticle()

    return () => {
      ourRequest.cancel()
    }
  }, [appState.article])

  if (isLoading)
    return (
      <Page title="...">
        <LoadingDotsIcon />
      </Page>
    )

  return (
    <>
      <div id="news" className="text-container">
        <Link to={article.link} className="thumb" title={article.title.rendered}>
          <img src={article.better_featured_image.source_url} alt={article.better_featured_image.caption} />
        </Link>
        <Link to={article.link} className="title" title={article.title.rendered}>
          {article.title.rendered}
        </Link>
        <Link to="/" className="source-logo" title="Home">
          <img src="/img/logo-aleph-news.png" alt="Logo Aleph News" />
        </Link>
      </div>
    </>
  )
}

export default Article
