import React from "react"
import Container from "./Container"

function Page(props) {
  return (
    <>
      <Container>{props.children}</Container>
    </>
  )
}

export default Page
