import React, { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import DispatchContext from "../DispatchContext"
import StateContext from "../StateContext"

function HeaderLeft() {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext)
  const navigate = useNavigate()

  const testClick = useCallback(i => {
    return () => appDispatch({ type: "gotoSlide", index: i })
  }, [])

  const handleMenuClick = useCallback(() => {
    return () => {
      appDispatch({ type: "toggleDrawer" })
    }
  }, [])

  const handleLogoClick = useCallback(i => {
    return () => {
      appDispatch({ type: "setActiveTab", value: "" })
      navigate("/")
    }
  }, [])

  return (
    <>
      <div className="header-left">
        <button type="button" id="menu-btn" className="menu-btn" onClick={handleMenuClick()}>
          <FontAwesomeIcon icon={faBars} />
        </button>

        <h1 className="logo">
          <button type="button" title="Home" className="logo" onClick={handleLogoClick()}>
            <img src="/img/AlephLogo.png" alt="" />
          </button>
        </h1>
        <div className={`stream-btns ${appState.drawerOpen ? "opened" : ""}`}>
          <button className="alephnews-btn" type="button" onClick={testClick(0)}>
            <img src="/img/logo-aleph-news.png" alt="" />
          </button>
          <button className="alephbusiness-btn" type="button" onClick={testClick(1)}>
            <img src="/img/logo-aleph-business.png" alt="" />
          </button>
          <button className="smartv-btn" type="button" onClick={testClick(2)}>
            <img src="/img/logo-smart-tv.png" alt="" />
          </button>
        </div>
      </div>
    </>
  )
}

export default HeaderLeft
