import React, { useEffect, useState, useContext } from "react"
import Axios from "axios"
import { useParams, Link } from "react-router-dom"

import Page from "./Page"
import LoadingDotsIcon from "./LoadingDotsIcon"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

function CategListPage(props) {
  const { slug } = useParams()
  const categTitles = {
    5: "Business",
    9: "Educație",
    12: "Entertainment",
    6: "Government",
    7: "Justiție",
    217: "Meteo",
    216: "Sănătate",
    13: "Sport",
    218: "Tehnologie",
    1: "Cultură"
  }

  const [isLoading, setIsLoading] = useState(true)
  const [articlesCategs, setArticlesCategs] = useState()
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)
  let articlesCategsSource = [process.env.Trending || "https://alephnews.ro/wp-json/wp/v2/posts"]
  articlesCategsSource = articlesCategsSource + "?categories=" + slug

  useEffect(() => {
    appDispatch({ type: "toggleSidebar", value: true })

    const ourRequest = Axios.CancelToken.source()
    async function fetchCategArticles() {
      try {
        const response = await Axios.get(articlesCategsSource, { cancelToken: ourRequest.token })
        setArticlesCategs(response.data)
        setIsLoading(false)
      } catch (e) {
        console.log("Nu am putut descărca json-ul cu cele mai citite articole de la {articlesMostReadSource} sau requestul a fost întrerupt la cerere.")
      }
    }
    fetchCategArticles()
    return () => {
      ourRequest.cancel()
    }
  }, [appState.articlesCategs])

  useEffect(() => {
    document.title = `${props.title} | Aleph`
    window.scrollTo(0, 0)
  }, [props.title])

  if (isLoading)
    return (
      <Page title="...">
        <LoadingDotsIcon />
      </Page>
    )

  return (
    <>
      <aside id="sidebar" className={`sidebar ${appState.sidebarClass}`}>
        <div id="most-read" className="text-container">
          <p className="head">{categTitles[slug]}</p>
          <ul className="news">
            {Object.keys(articlesCategs).map((key, index) => {
              return (
                <li key={articlesCategs[key].id}>
                  <Link to={`/article/${articlesCategs[key].id}`}>{articlesCategs[key].title.rendered}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
    </>
  )
}

export default CategListPage
