import React, { useContext } from "react"
import Banner970_1 from "./Banner970_1"
import StateContext from "../StateContext"
function Container(props) {
  const appState = useContext(StateContext)

  return (
    <>
      <div className={appState.sidebarShown ? "wrapper container sidebar-active" : "wrapper container"}>
        <div className={"main-block"}>{props.children}</div>
        <Banner970_1 />
      </div>
    </>
  )
}

export default Container
