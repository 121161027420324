import React, { useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVolumeHigh, faExpand } from "@fortawesome/free-solid-svg-icons"

function Overlay(props) {
  const playerRef = props.forwardedRef
  const handleMute = useCallback(i => {
    return () => {
      const player = playerRef.current
      player.muted(!player.muted())
    }
  }, [])

  const handleAd = useCallback(i => {
    return () => {
      window.location.href = "https://www.coca-cola.ro"
    }
  }, [])

  const handleExpand = useCallback(i => {
    return () => {
      const player = playerRef.current
      if (player.isFullscreen()) {
        player.exitFullWindow()
      } else {
        player.enterFullWindow()
      }
    }
  }, [])

  return (
    <>
      <div className="player-overlay">
        <div className="a-ad" onClick={handleAd()}></div>
        <div className="buttons-container">
          <div className="a-volume" onClick={handleMute()}>
            <FontAwesomeIcon icon={faVolumeHigh} />
          </div>
          <div className="a-expand" onClick={handleExpand()}>
            <FontAwesomeIcon icon={faExpand} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Overlay
