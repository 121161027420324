import React, { useEffect } from "react"
import Sections from "./Sections"
import UserZone from "./UserZone"

function HeaderRight() {
  return (
    <>
      <div className="header-right">
        <Sections />
        {/* <UserZone /> */}
      </div>
    </>
  )
}

export default HeaderRight
