import React, { useEffect, StrictMode } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useImmerReducer } from "use-immer"

import StateContext from "./StateContext"
import DispatchContext from "./DispatchContext"

import io from "socket.io-client"

import MainEl from "./components/MainEl"
import Page from "./components/Page"
import Header from "./components/Header"
import Homepage from "./components/Homepage"
import TrendingPage from "./components/TrendingPage"
import MostReadPage from "./components/MostReadPage"
import ForYouPage from "./components/ForYouPage"
import CategsPage from "./components/CategsPage"
import CategListPage from "./components/CategListPage"
import ArticlePage from "./components/ArticlePage"

import "./main.css"

function Main() {
  const sidebarClassNames = ["an", "ab", "st"]
  const videoNames = ["Aleph News", "Aleph Business", "Smart TV"]

  const initialState = {
    videoLeft: "Smart TV",
    videoRight: "Aleph Business",
    sidebarClass: "an",
    videoSourceIdx: 0,
    videoSources: [],
    articlesTrending: [],
    articlesMostRead: [],
    articlesForYou: [],
    articlesCategs: [],
    categs: [],
    orientation: "landscape",
    currentlyPlaying: "",
    drawerOpen: false,
    sidebarShown: false,
    sourcesReady: false,
    activeTab: ""
  }

  function appReducer(draft, action) {
    switch (action.type) {
      case "rotateSilderLeft": {
        draft.videoSourceIdx = draft.videoSourceIdx - 1
        if (draft.videoSourceIdx < 0) {
          draft.videoSourceIdx = 2
        }
        let prevVideo = draft.videoSourceIdx - 1
        if (prevVideo < 0) {
          prevVideo = 2
        }
        draft.videoLeft = videoNames[prevVideo]
        let nextVideo = draft.videoSourceIdx + 1
        if (nextVideo > 2) {
          nextVideo = 0
        }
        draft.videoRight = videoNames[nextVideo]
        draft.sidebarClass = sidebarClassNames[draft.videoSourceIdx]
        return
      }
      case "rotateSilderRight": {
        draft.videoSourceIdx = draft.videoSourceIdx + 1
        if (draft.videoSourceIdx > 2) {
          draft.videoSourceIdx = 0
        }
        let prevVideo = draft.videoSourceIdx - 1
        if (prevVideo < 0) {
          prevVideo = 2
        }
        draft.videoLeft = videoNames[prevVideo]
        let nextVideo = draft.videoSourceIdx + 1
        if (nextVideo > 2) {
          nextVideo = 0
        }
        draft.videoRight = videoNames[nextVideo]
        draft.sidebarClass = sidebarClassNames[draft.videoSourceIdx]
        return
      }
      case "setOrientation": {
        draft.orientation = action.value
        return
      }
      case "setVideoSources": {
        draft.videoSources = action.value
        return
      }
      case "gotoSlide": {
        draft.videoSourceIdx = action.index
        draft.sidebarClass = sidebarClassNames[draft.videoSourceIdx]
        return
      }
      case "toggleDrawer": {
        draft.drawerOpen = !draft.drawerOpen
        return
      }
      case "toggleSidebar": {
        draft.sidebarShown = action.value
        return
      }
      case "setActiveTab": {
        draft.activeTab = action.value
        return
      }
    }
  }

  const [state, dispatch] = useImmerReducer(appReducer, initialState)

  // Get initial state: orientation, video sources, trending articles, most read articles, for you articles
  useEffect(() => {
    // Get orientation
    const orientation = window.matchMedia("(orientation: portrait)").matches ? "portrait" : "landscape"
    dispatch({ type: "setOrientation", value: orientation })
  }, [])

  // Set event for orientation change
  useEffect(() => {
    function handleOrientationChange() {
      const orientation = window.matchMedia("(orientation: portrait)").matches ? "landscape" : "portrait" // this is strange, it dispaches the opposite of what it should
      dispatch({ type: "setOrientation", value: orientation })
    }
    window.addEventListener("orientationchange", handleOrientationChange)
    return () => window.removeEventListener("orientationchange", handleOrientationChange)
  }, [])

  // Catch events from push.grasu.net
  useEffect(() => {
    const socket = io("https://push.grasu.net")

    // Ascultați evenimente de la server
    socket.on("newsyncurl", data => {
      // console.log("Mesaj primit de la server: ", data.data.message)
    })

    // For debug: ascultați toate evenimentele
    // socket.onAny(event => {
    //   console.log(`ANY ${event}`)
    // })

    return () => {
      socket.off("newsyncurl").disconnect()
    }
  }, [])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Header />
          <Page>
            <MainEl />
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/trending" element={<TrendingPage title="Trending" />} />
              <Route path="/most-read" element={<MostReadPage title="Most read" />} />
              <Route path="/for-you" element={<ForYouPage title="For you" />} />
              <Route path="/categories" element={<CategsPage title="Categories" />} />
              <Route path="/categories/:slug" element={<CategListPage title="Categories" />} />
              <Route path="/article/:id" element={<ArticlePage />} />
            </Routes>
          </Page>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById("app"))
root.render(<Main />)

if (module.hot) {
  module.hot.accept()
}
