import React, { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine, faChartSimple, faList, faTh, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"

import DispatchContext from "../DispatchContext"
import StateContext from "../StateContext"

function Sections() {
  const navigate = useNavigate()

  const dispatchEvent = useContext(DispatchContext)
  const appState = useContext(StateContext)

  const handleSectionsClick = useCallback(i => {
    return () => {
      dispatchEvent({ type: "setActiveTab", value: i })
      navigate(i)
    }
  }, [])

  const goHome = useCallback(() => {
    return () => {
      dispatchEvent({ type: "setActiveTab", value: "" })
      navigate("/")
    }
  }, [])

  return (
    <>
      <div className="sections">
        <button className={appState.activeTab === "trending" ? "trending active" : "trending"} type="button" onClick={appState.activeTab === "" ? handleSectionsClick("trending") : goHome()}>
          <span className="text">Trending</span>
          <span className="graph">
            <FontAwesomeIcon icon={faChartLine} />
          </span>
        </button>
        <button className={appState.activeTab === "most-read" ? "most-read active" : "most-read"} type="button" onClick={appState.activeTab === "" ? handleSectionsClick("most-read") : goHome()}>
          <span className="text">Cele mai citite</span>
          <span className="graph">
            <FontAwesomeIcon icon={faChartSimple} />
          </span>
        </button>
        <button className={appState.activeTab === "for-you" ? "for-you active" : "for-you"} type="button" onClick={appState.activeTab === "" ? handleSectionsClick("for-you") : goHome()}>
          <span className="text">Pentru tine</span>
          <span className="graph">
            <FontAwesomeIcon icon={faList} />
          </span>
        </button>
        <button className={appState.activeTab === "categories" ? "categories active" : "categories"} type="button" onClick={appState.activeTab === "" ? handleSectionsClick("categories") : goHome()}>
          <span className="text">Categorii</span>
          <span className="graph">
            <FontAwesomeIcon icon={faTh} />
          </span>
        </button>
        {/* <button to="/search" className="search-btn" type="button">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button> */}
      </div>
    </>
  )
}

export default Sections
