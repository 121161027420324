import React, { useEffect, useState, useContext } from "react"
import Axios from "axios"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine } from "@fortawesome/free-solid-svg-icons"

import Page from "./Page"
import LoadingDotsIcon from "./LoadingDotsIcon"
import StateContext from "../StateContext"

function Trending(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [articlesTrending, setTrending] = useState()
  const appState = useContext(StateContext)
  const articlesTrendingSource = [process.env.Trending || "https://alephnews.ro/wp-json/wp/v2/posts"]

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source()
    async function fetchTrending() {
      try {
        const response = await Axios.get(articlesTrendingSource, { cancelToken: ourRequest.token })
        setTrending(response.data)
        setIsLoading(false)
      } catch (e) {
        console.log("Nu am putut descărca json-ul cu articoletrending  de la {articlesTrendingSource} sau requestul a fost întrerupt la cerere.")
      }
    }
    fetchTrending()
    return () => {
      ourRequest.cancel()
    }
  }, [appState.articlesTrending])

  if (isLoading)
    return (
      <Page title="...">
        <LoadingDotsIcon />
      </Page>
    )

  return (
    <>
      <div id="trending" className="text-container">
        <p className="head">
          <FontAwesomeIcon icon={faChartLine} />
          Trending
        </p>
        <ul className="news">
          {Object.keys(articlesTrending).map((key, index) => {
            return (
              <li key={articlesTrending[key].id}>
                <Link to={`/article/${articlesTrending[key].id}`}>{articlesTrending[index].title.rendered}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default Trending
