import React, { useEffect, useState, useContext } from "react"
import Axios from "axios"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartSimple } from "@fortawesome/free-solid-svg-icons"

import Page from "./Page"
import LoadingDotsIcon from "./LoadingDotsIcon"
import StateContext from "../StateContext"

function MostRead() {
  const [isLoading, setIsLoading] = useState(true)
  const [articlesMostRead, setMostRead] = useState()
  const appState = useContext(StateContext)
  const articlesMostReadSource = [process.env.MostRead || "https://alephnews.ro/wp-json/app/v1/top-citite"]

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source()
    async function fetchMostRead() {
      try {
        const response = await Axios.get(articlesMostReadSource, { cancelToken: ourRequest.token })
        setMostRead(response.data)
        setIsLoading(false)
      } catch (e) {
        console.log("Nu am putut descărca json-ul cu cele mai citite articole de la {articlesMostReadSource} sau requestul a fost întrerupt la cerere.")
      }
    }
    fetchMostRead()
    return () => {
      ourRequest.cancel()
    }
  }, [appState.articlesMostRead])

  if (isLoading)
    return (
      <Page title="...">
        <LoadingDotsIcon />
      </Page>
    )

  return (
    <>
      <div id="most-read" className="text-container">
        <p className="head">
          <FontAwesomeIcon icon={faChartSimple} />
          Cele mai citite
        </p>
        <ul className="news">
          {Object.keys(articlesMostRead).map((key, index) => {
            return (
              <li key={articlesMostRead[key].id}>
                <Link to={`/article/${articlesMostRead[key].id}`}>{articlesMostRead[key].title}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default MostRead
