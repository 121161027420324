import React, { useEffect, useRef, useContext, forwardRef, memo, useState } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import StateContext from "../StateContext"
import Overlay from "./Overlay"

export const VideoJS = memo(
  forwardRef((props, ref) => {
    const videoRef = useRef(null)
    const playerRef = useRef(null)
    const { options, onReady } = props
    const appState = useContext(StateContext)
    const [savedOptions] = useState(options)

    useEffect(() => {
      // Make sure Video.js player is only initialized once
      if (!playerRef.current) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = document.createElement("video-js")

        videoElement.classList.add("vjs-big-play-centered")
        videoRef.current.appendChild(videoElement)

        const player = (playerRef.current = videojs(videoElement, options, () => {
          onReady && onReady(player)
        }))

        // You could update an existing player in the `else` block here
        // on prop change, for example:
      } else {
        const player = playerRef.current
        player.src(options.sources)

        // Here we'll change source based on appState.orientation or appState.videoSourceIdx
        if (appState.orientation === "portrait") {
          player.src(appState.videoSources[appState.videoSourceIdx].portrait)
          player.aspectRatio("9:16")
        } else {
          player.src(appState.videoSources[appState.videoSourceIdx].landscape)
          player.aspectRatio("16:9")
        }

        // build poster string
        const posterOrientation = appState.orientation === "portrait" ? "pp" : "pl"
        const poster = "/img/" + posterOrientation + "_" + appState.sidebarClass + ".png"
        player.poster(poster)
      }
    }, [savedOptions, videoRef, appState.orientation, appState.videoSourceIdx])

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const player = playerRef.current

      return () => {
        if (player && !player.isDisposed()) {
          playerRef.current = null
        }
      }
    }, [playerRef])

    return (
      <div data-vjs-player playsInline className="player-wrapper">
        <div playsInline ref={videoRef} />
        <Overlay forwardedRef={playerRef} />
      </div>
    )
  })
)

export default VideoJS
