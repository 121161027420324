import React from "react"
import HeaderRight from "./HeaderRight"
import HeaderLeft from "./HeaderLeft"

function Header() {
  return (
    <header className="site-header">
      <div className="wrapper">
        <div className="header-container">
          <HeaderLeft />
          <HeaderRight />
        </div>
      </div>
    </header>
  )
}

export default Header
