import React, { useContext, useRef } from "react"

import VideoJS from "./VideoJS"

import StateContext from "../StateContext"

function VideoCentral() {
  const appState = useContext(StateContext)
  const playerRef = useRef(null)
  let videoSource = appState.videoSources[appState.videoSourceIdx][appState.orientation]

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    playsinline: true,
    muted: true,
    sources: [
      {
        src: videoSource
      }
    ]
  }

  const handlePlayerReady = player => {
    playerRef.current = player
  }

  return (
    <>
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </>
  )
}

export default VideoCentral
