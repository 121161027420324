import React from "react"

function Banner970_1() {
  return (
    <>
      <div className="banner">
        <img src="/img/ads/970x250.jpg" alt="banner" />
      </div>
    </>
  )
}

export default Banner970_1
