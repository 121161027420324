import React, { useContext, useEffect } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"
import Article from "./Article"

function ArticlePage() {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  useEffect(() => {
    appDispatch({ type: "toggleSidebar", value: true })
  }, [])

  return (
    <>
      <aside id="sidebar" className={`sidebar ${appState.sidebarClass}`}>
        <Article />
      </aside>{" "}
    </>
  )
}

export default ArticlePage
