import React, { useContext } from "react"
import DispatchContext from "../DispatchContext"
import StateContext from "../StateContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp } from "@fortawesome/free-solid-svg-icons"

function SliderLeft() {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext)

  function rotateLeft() {
    appDispatch({ type: "rotateSilderLeft" })
  }

  return (
    <>
      <button type="button" data-sursa="alephbusiness" className="slider-btn slider-btn-left" id="stream-left" onClick={rotateLeft}>
        <p>
          <FontAwesomeIcon icon={faAngleUp} />
        </p>
      </button>
    </>
  )
}

export default SliderLeft
